<template>
    <p class="font-bold mb-5">Ця Угода визначає умови та правила використання Онлайн платформи CupCult
        (https://cupcult.co/) та укладається між Користувачем та Власником Маркетплейсу.</p>
    <p class="font-bold">Ця Угода не може розумітися як встановлення між Користувачем з одної сторони та Власником Маркетплейсу з іншої сторони агентських відносин, доручень, відносин по спільній діяльності, трудових відносин, або яких-небудь інших відносин, прямо не передбачених дійсною Угодою.</p>

    <FormattedStaticPage :data="data"/>
</template>

<script>
import FormattedStaticPage from "@/Components/Base/FormattedStaticPage.vue";

export default {
    components: {FormattedStaticPage},
    data() {
        const platformName = this.$page.props.appName
        const siteUrl = document.location.origin

        return {
            data: [
                {
                    title: '1. Терміни та визначення',
                    items: [
                        'У цій Угоді користувача терміни використовуються в таких значеннях:',
                        '1.1. Онлайн платформа CupCult (https://cupcult.co/) (далі також - Маркетплейс) - це інтернет-ресурс, спеціально створений для здійснення Покупцями пошуку товарів, що реалізується безпосередньо Продавцями.',
                        '1.2. Адміністрація Маркетплейсу (далі також - Власник Маркетплейсу) - ФОП Сірик Микола Іванович (ідентифікаційний код 3525214956).',
                        '1.3. Відвідувач - будь-яка особа, яка має доступ до Маркетплейсу, за допомогою мережі Інтернет і використовує Маркетплейс.',
                        '1.4. Інформаційний матеріал - будь-яка інформація, яка розміщується Користувачем на Маркетплейсі, включаючи, але не обмежуючись: назва та опис Товарних позицій, фотографії, характеристики Товару, інформація про Продавця тощо.',
                        '1.5. Користувач - Продавець, Покупець або Відвідувач, які погодилися з умовами цієї Угоди.',
                        '1.6. Обліковий запис/Особистий кабінет - електронний кабінет Користувача (аккаунт) у функціональній системі Маркетплейсу.',
                        '1.7. Товарна позиція - комерційна пропозиція Продавця про продаж Товару, яка публікується ним на Маркетплейсі.',
                        '1.8. Товар - продукція, зазначена в електронній заявці, щодо якого між Продавцем та Покупцем дистанційним способом укладено договір купівлі-продажу.',
                        '1.9. Покупець - будь-яка дієздатна фізична або юридична особа, фізична особа - підприємець, яка прийняла умови цієї Угоди і яка, користуючись можливостями Маркетплейсу, купує і/або замовляє запропоновані Продавцями товари. Після реєстрації на Маркетплейсі Покупець отримує доступ до Особистий кабінету покупця, де міститься інформація про нього, історія замовлень, інша інформація та за допомогою якого він може робити замовлення, не вводячи повторно свої дані.',
                        '1.10. Політика конфіденційності - умови роботи з конфіденційною інформацією на Маркетплейсі, що є невід\'ємною частиною цієї Угоди і розміщена за посиланням: https://cupcult.co/pages/privacy-policy.',
                        '1.11. Продавець - юридична особа або фізична особа-підприємець, яка здійснює діяльність з продажу Товарів. Після реєстрації на Маркетплейсі Продавець отримує доступ до Особистого кабінету продавця, в якому він може публікувати контактну інформацію, інформацію про товари, що пропонуються Продавцем та будь-яку іншу інформацію, безпосередньо пов\'язану з Продавцем.',
                        '1.12. Послуги - сукупність послуг, які надаються Користувачам на Маркетплейсі на платній або безоплатній основі, в тому числі, але не виключно, послуги з реєстрації Користувачів на Маркетплейсі, створення Облікового запису, використання Маркетплейсу для надання доступу, відображення товарів Продавця в каталозі, а також комплекс дій, пов\'язаних з наданням Покупцям і Відвідувачам можливості вибору та замовлення товарів Продавців, їх комунікацій стосовно укладення дистанційного договору купівлі-продажу товару, та інше.',
                        '1.13. Угода користувача - Угода, яка доступна на сторінці:https://cupcult.co/. Під дію цієї Угоди підпадають всі існуючі (функціонуючі) на даний момент Послуги Маркетплейсу, а також будь-які їх подальші модифікації.',
                        '1.14. Замовлення - відомості про факт покупки Покупцем Товару, належним чином оформлені в Особистому кабінеті на сайті https://cupcult.co/. Інформація про замовлення доступна в Особистому кабінеті покупця на платформі Маркетплейсу. Користувач може змінити або скасувати Замовлення відповідно до умов замовлення та доставки.',
                    ]
                },
                {
                    title: '2. Загальні положення',
                    items: [
                        '2.1. Зміст Маркетплейсу захищено авторським правом, законодавством про товарні знаки, а також іншими правами, пов\'язаними з інтелектуальною власністю, і законодавством про недобросовісну конкуренцію.',
                        '2.1.1. Будь-яке використання елементів, символіки, текстів, графічних зображень, програм та інших об\'єктів, що входять до складу Послуг і належать Маркетплейсу, крім дозволеного в цій Угоді, без дозволу Адміністрації Маркетплейсу чи іншого законного правовласника є незаконним і може стати причиною судового розгляду і притягнення порушників до цивільно-правової, адміністративної та кримінальної відповідальності відповідно до законодавства України.',
                        '2.2. Моментом укладення цієї Угоди вважається момент будь-якої взаємодії Користувача з Маркетплейсом. Цим Користувачі підтверджують свою згоду з усіма умовами цієї Угоди користувача, а Маркетплейс надає їм персональне, невиключне, невідчужуване, обмежене право на вхід і використання Маркетплейсу, а також Послуг на умовах, викладених в цій Угоді.',
                        '2.3. Будь-яка взаємодія Користувача з Маркетплейсом (у тому числі, але не виключно, реєстрація на Маркетплейсі, підписання заяви про приєднання, перерахування Користувачем грошових коштів Власнику Маркетплейсу) означає безумовну згоду Користувача з усіма пунктами цієї Угоди. Така взаємодія з Маркетплейсом є повним і безумовним акцептом цієї Угоди, незнання якої не звільняє Користувача від відповідальності за невиконання її умов.',
                        '2.4. Угода користувача може бути змінена Адміністрацією Маркетплейсу без попереднього повідомлення. Продовження користування Маркетплейсом Користувачем (будь-яка його подальша взаємодія з Маркетплейсом) означає його згоду зі змінами в Угоді.',
                        '2.5. У разі, якщо Користувач не згоден з будь-якими умовами цієї Угоди та/або не згоден дотримуватися умов цієї Угоди (або нової редакції Угоди), він має право не користуватися можливостями/Послугами Маркетплейсу і зобов\'язаний припинити використання Маркетплейсу та покинути його, а також розірвати договірні відносини із Маркетплейсом.',
                        '2.6. Адміністрація Маркетплейсу має право в будь-який момент відмовити будь-якому Користувачеві в реєстрації і/або в користуванні Маркетплейсом в разі порушення ним умов цієї Угоди.',
                        '2.7. Дійсна Угода регулюється і тлумачиться відповідно до законодавства України. Питання, не врегульовані цією Угодою, підлягають вирішенню відповідно до чинного законодавства України.',
                        '2.8. Всі можливі суперечки, що випливають з відносин, врегульованих цією Угодою, повинні вирішуватися шляхом переговорів. Спори, за якими сторони не дійшли взаємної згоди і, які неможливо було врегулювати шляхом переговорів вирішуються у судовому порядку відповідно до чинного законодавства України.',
                    ]
                },
                {
                    title: '3. Послуги та Обліковий запис Користувача',
                    items: [
                        '3.1. Маркетплейс надає Послуги, що дозволяють Користувачам реєструватися та створювати на Маркетплейсі Облікові записи, розміщувати Товарні позиції з метою пропонування до продажу товарів, ознайомлюватися з пропозиціями Продавців та здійснювати покупки.',
                        '3.2. З метою отримання доступу до всіх без винятку Послуг Маркетплейсу, Користувачеві необхідно пройти процедуру реєстрації, в результаті якої для Користувача буде створено унікальний обліковий запис (особистий кабінет).',
                        '3.2.1. Перегляд Покупцем товару, що пропонується Продавцями до продажу на Маркетплейсі, а також оформлення Покупцем Замовлення не потребують попередньої реєстрації.',
                        '3.3. Користувач може пройти процедуру реєстрації одним із таких способів:',
                        '3.3.1. здійснити низку послідовних дій щодо заповнення реєстраційного бланка в електронній формі, в результаті яких зареєстрований Користувач отримує доступ до особистого кабінету (профілю) для здійснення доступу до можливостей Маркетплейсу, доступ до яких надається Користувачеві через єдиний профіль;',
                        '3.3.2. здійснити низку послідовних дій для замовлення товарів, пропонованих на Маркетплейсі: вибрати товар у каталозі та натиснути кнопку «Замовити» (або іншу кнопку, передбачену функціоналом Маркетплейсу), а після автоматичного переходу на сторінку замовлення ввести необхідні дані;',
                        '3.3.3. здійснити низку послідовних дій щодо введення персональних даних вже існуючого облікового запису соціальної мережі Користувача.',
                        '3.4. Користувач розуміє та погоджується з тим, що під час реєстрації необхідно ввести свій номер мобільного телефону та за допомогою дзвінка підтвердити (валідувати) його. Маркетплейс запам’ятає номер і асоціює його з конкретним Користувачем. Усі наступні входи в систему Маркетплейсу здійснюватимуться за допомогою вказаного номера телефону.',
                        '3.5. Вхід до Облікового запису користувача також можливий за ключем доступу, який зберігатиметься виключно на пристрої Користувача.',
                        '3.6. Для реєстрації Користувач зобов\'язується не лише надати достовірну та повну інформацію, але і підтримувати цю інформацію в актуальному стані.',
                        '3.7. Користувач має право у будь-який момент видалити свій профіль на Маркетплейсі. Видалення профілю тягне за собою припинення доступу до певних функцій Маркетплейсу, доступ до яких здійснювався Користувачем через обліковий запис.',
                        '3.8. Покупцям Послуги надаються безкоштовно, а Продавцям - на підставі та у відповідності до укладених договорів про надання послуг, в яких, зокрема, визначається вартість послуг і актуальні реквізити для переказу грошових коштів.',
                        '3.9. Власник Маркетплейсу не є стороною угоди між Продавцем та Покупцем, предметом якої виступають товари, пропоновані Продавцями на Онлайн платформі СupСult. Всі угоди між Продавцями та Покупцями укладаються ними безпосередньо, Власник Маркетплейсу не є учасником таких угод.',
                    ]
                },
                {
                    title: '4. Права та обов\'язки Адміністрації Маркетплейсу',
                    items: [
                        '\n' +
                        '4.1. Адміністрація Маркетплейсу зобов\'язується забезпечити можливість отримання Користувачем Послуг в порядку, визначеному цією Угодою.',
                        '4.2. У разі порушення Користувачем умов цієї Угоди, Адміністрація Маркетплейсу має право призупинити, обмежити або припинити доступ такого Користувача до Послуг в односторонньому порядку в будь-який час. При цьому Маркетплейс не несе відповідальності за будь-які збитки, які можуть бути нанесені Користувачеві такими діями.',
                        '4.3. Адміністрація Маркетплейсу має право розміщувати рекламну і/або іншу інформацію без узгодження з Користувачем.',
                        '4.4. Адміністрація Маркетплейсу має право здійснювати розсилання Користувачам повідомлень, в тому числі електронних, що містять інформацію про Послуги, діяльність Власника Маркетплейсу або його партнерів, організаційно-технічну, інформаційну, маркетингову інформацію, комерційні пропозиції, а також будь-яку іншу інформацію, що безпосередньо пов`язана з наданням Користувачам послуг на Маркетплейсі. Такі повідомлення надходитимуть на контактні дані, надані Користувачем в Обліковому записі або отримані від Користувача іншим законним способом. Користувач у будь-який момент може відмовитися від подальшого отримання повідомлень, крім тих, що прямо пов`язані з наданням послуг на Маркетплейсі.',
                        '4.5. Адміністрація Маркетплейсу зобов\'язується надавати Користувачу можливість отримання консультацій служби підтримки у разі зазначення Користувачем ідентифікаційних даних. Обсяг консультацій обмежується конкретними питаннями, пов\'язаними з наданням Послуг.',
                        '4.6. У разі, якщо Адміністрація Маркетплейсу отримає відомості про незаконну діяльність Користувачів та/або встановить факти чи з’ясує обставини, які вказують на те, що діяльність Користувачів має ознаки незаконної, з метою забезпечення безпеки Користувачів і роботи Маркетплейсу, а також з метою запобігання та припинення шахрайських дій на Маркетплейсі, має право в односторонньому порядку призупинити, обмежити або зупинити доступ такого Користувача до будь-якої Послуги Маркетплейсу в будь-який час.',
                        '4.7. Адміністрація Маркетплейсу з метою забезпечення безпеки Користувачів та роботи Маркетплейсу, а також з метою запобігання та зупинення шахрайських дій на Маркетплейсі має право запитувати документи, що ідентифікують Користувачів (наприклад, виписку з Єдиного державного реєстру юридичних осіб, фізичних осіб та громадських формувань для фізичних осіб та юридичних осіб). Також, з метою підтвердження добросовісності діяльності Продавця, Адміністрація Маркетплейсу має право запитувати у Продавців документи, що підтверджують відправку товарів Покупцям. У випадку відмови надати документи, Адміністрація Маркетплейсу має право в односторонньому порядку призупинити, обмежити або зупинити доступ такого Користувача до Послуг в будь-який час.',
                    ]
                },
                {
                    title: '5. Права та обов\'язки Користувача',
                    items: [
                        '5.1 Під час реєстрації та/або заповнення форми замовлення Користувач повинен надати правдиву, точну і повну інформацію про себе з питань, які пропонуються в формі для реєстрації Маркетплейсу і підтримувати цю інформацію в актуальному стані, такому, що відповідає дійсності. Користувач зобов\'язується забезпечити достовірність наданої інформації. Користувач не має права вимагати від Адміністрації Маркетплейсу приховування будь-якої інформації про нього.',
                        '5.2. Шляхом підтвердження Замовлення при його оформленні на Маркетплейсі, Користувач дає згоду на автоматичне створення Кабінету Покупця, для входу в який використовуватиметься номер мобільного телефону, вказаний при оформленні замовлення на Маркетплейсі. При цьому, Покупець в будь-який момент може видалити свій Кабінет Покупця, за умови, якщо він має до нього доступ.',
                        '5.3 Користувач зобов\'язується використовувати Маркетплейс тільки в законних цілях, дотримуватися чинного законодавства України, а також прав та законних інтересів Власника Маркетплейсу.',
                        '5.4. Користувач зобов\'язується не здійснювати дій, спрямованих на отримання доступу до чужого особистого профілю/Облікового запису.',
                        '5.5. Користувач не має права передавати, відступати, продавати, передавати в користування тощо свої дані для доступу до Маркетплейсу третім особам без згоди Адміністрації Маркетплейсу. У разі передачі даних третій особі, всю відповідальність за дії такої третьої особи після цього несе Користувач.',
                        '5.6. Користувач зобов\'язується не використовувати самостійно або із залученням третіх осіб можливості Маркетплейсу з метою, які можуть бути кваліфіковані як порушення прав третіх осіб на об\'єкти інтелектуальної власності, недобросовісна конкуренція, інше порушення чинного законодавства України.',
                        '5.7. Продавці гарантують, що володіють всіма правами на Товар, що розміщується ними на Маркетплейсі, у т.ч. правами інтелектуальної власності.',
                        '5.8. Користувач зобов\'язується надати за запитом від Адміністрації Маркетплейсу додаткову інформацію, яка має безпосереднє відношення до надання послуг.',
                        '5.9. Користувачі Маркетплейсу зобов\'язуються не допускати в адресу і щодо інших користувачів, Адміністрації та представників Маркетплейсу виразів і висловлювань у брутальній, образливій формі, проявляти агресію і застосовувати в своїх висловлюваннях (як усних, так і письмових) ненормативну лексику. У разі отримання скарги або фіксації Адміністрацією Маркетплейсу перерахованих вище дій, Адміністрація Маркетплейсу має право виставити такому Користувачеві попередження та/або обмежити доступ такого Користувача до Маркетплейсу і/або відмовитися від надання Послуг.',
                        '5.10. Продавці, що пропонують до продажу товари на Маркетплейсі, зобов’язуються не дублювати інформацію, не наповнювати однаковими (повністю або частково) товарними позиціями в тому числі, але не виключно, якщо товарні позиції містять часткове або повне співпадіння про товари, його опис, характеристики, ціну.',
                    ]
                },
                {
                    title: '6. Вимоги до Інформаційного матеріалу',
                    items: [
                        '6.1. Публікація інформації на Маркетплейсі повинна здійснюватися в повній відповідності з вимогами чинного законодавства України, положень цієї Угоди.',
                        '6.2. На Маркетплейсі заборонено продаж будь-яких товарів, що порушують чинне законодавство України і які можуть бути розцінені як непристойні, образливі або такі що вводять в оману. Зокрема, заборона поширюється на підроблені товари, що містять торгову марку або логотип, ідентичний торговій марці або логотипу іншого товару або практично не відрізняється від нього (в т.ч. репліки, копії без логотипу).',
                        '6.3. Пропозиція Продавця про продаж товару (Товарна позиція), яка публікується ним на Маркетплейсі, має містити всю передбачену законодавством України (зокрема, але не виключно, Законом України «Про електронну комерцію», Законом України «Про захист прав споживачів») інформацію для споживачів.',
                        '6.4. Адміністрація Маркетплейсу не проводить обов\'язкову перевірку інформації, яка розміщується Користувачами на Маркетплейсі і не несе відповідальності за відповідність такої інформації чинному законодавству України.',
                        '6.5. Адміністрація Маркетплейсу не несе відповідальності за помилки, неточності, упущення, які були допущені при реєстрації або розміщенні інформації Користувачем, а також будь-які матеріальні або нематеріальні збитки, що виникли в зв\'язку з цим (включаючи упущену вигоду).',
                        '6.6. Адміністрація Маркетплейсу не несе відповідальності за використання (як правомірне, так і неправомірне) третіми особами інформації, розміщеної на Маркетплейсі, включаючи її відтворення і поширення, здійснені як в рамках Маркетплейсу, так і іншими можливими способами.',
                        '6.7. Адміністрація Маркетплейсу залишає за собою право в односторонньому порядку видаляти розміщений Користувачами Інформаційний матеріал на вимогу від правовласників або компетентних державних органів, а також у разі, якщо розміщена інформація порушує права (у т.ч. права інтелектуальної власності) третіх осіб, або не відповідає, на думку адміністрації Маркетплейсу, принципам суспільної моралі.',
                        'Адміністрація Маркетплейсу також має право вимагати у Користувача підтверджуючі документи на розміщену інформацію.',
                        '6.8. У разі виникнення в зв\'язку з розміщенням Користувачем будь-якої інформації та/або якістю товарів, претензій, суперечок застосування штрафних санкцій з боку третіх осіб та/або контролюючих органів або за рішенням суду, такий Користувач зобов\'язується розглядати та вирішувати ці питання самостійно без залучення Адміністрації Маркетплейсу, а також зобов\'язується компенсувати Власнику Маркетплейсу в повному обсязі збитки, витрати, включаючи штрафні санкції, викликані порушенням ним умов цієї Угоди.',
                        '6.9. Вся розміщена Користувачами на Маркетплейсі інформація вважається власністю Користувачів, які її розмістили, до тих пір, поки не буде підстав вважати інакше. Всю відповідальність за відповідність такої інформації законодавству України несуть такі Користувачі. Користувачі Маркетплейсу не отримують ніяких прав на використання інформації, розміщеної іншими Користувачами і несуть повну відповідальність перед власником інформації за її неправомірне використання.',
                    ]
                },
                {
                    title: '7. Відмова від гарантій та відповідальність',
                    items: [
                        '7.1. За порушення умов цієї Угоди сторони несуть відповідальність, передбачену чинним законодавством України.',
                        '7.2. Адміністрація Маркетплейсу не несе відповідальності перед Користувачем за обмеження доступу до Послуг, за припинення доступу до Послуг, якщо ці обмеження і припинення виникли внаслідок обставин непереборної сили, що виникли після акцептування цієї Угоди та на настання яких Адміністрація Маркетплейсу не могла вплинути, включаючи, але не обмежуючись, наступним: війна, заколоти, страйки, саботаж, ембарго, пожежі, повені, стихійні лиха, погіршення радіоелектронної або радіологічної обстановки, вибухи, дії або бездіяльність уряду України або іншої країни, акти державних органів і/або органів місцевого самоврядування, внесення змін до законодавства України, аварії на мережах загального користування, зміни умов доступу до лінійно-кабельним засобів зв\'язку та інше.',
                        '7.3. Адміністрація Маркетплейсу не нестиме відповідальність за будь-які збитки, які прямо або побічно виникли у Покупців або Продавців в результаті діяльності Продавців на Маркетплейсі. Адміністрація Маркетплейсу ні за яких обставин не несе відповідальності перед Користувачами або третіми особами за будь-які непрямі збитки, у тому числі упущену вигоду. Відповідальність Адміністрації Маркетплейсу обмежується прямими документально підтвердженими збитками, що наступили в результаті дії або бездіяльності Адміністрації Маркетплейсу в розмірі, що не перевищує вартість наданих послуг окремо взятому Користувачеві.',
                        '7.4. Адміністрація Маркетплейсу не несе відповідальності за будь-які збитки електронним пристроям Користувача або іншої особи, будь-якого іншого обладнання або програмного забезпечення, викликані або пов\'язані з використанням Користувачем Маркетплейсу.',
                        '7.5. Адміністрація Маркетплейсу не несе відповідальності перед Користувачами або іншими третіми особами щодо:',
                        '7.5.1 змісту і законності, достовірності і повноти інформації, що надається/отримується Користувачем при використанні Послуг;',
                        '7.5.2 якості товарів, що продаються Продавцями на Маркетплейсі, вчасну доставку;',
                        '7.5.3 відповідності товарів, придбаних за допомогою Маркетплейсу, очікуванням і/або вимогам Покупця;',
                        '7.5.4 виконання Продавцями своїх зобов\'язань по доставці Покупцям придбаних за допомогою Маркетплейсу товарів, їх повернення або заміни, повернення коштів у разі розірвання договору тощо;',
                        '7.5.5 виконання гарантійних зобов\'язань Продавцями перед Покупцями по придбаних за допомогою Маркетплейсу товарів;',
                        '7.5.6 відповідності діяльності, здійснюваної Продавцем, з продажу товарів вимогам законодавства України;',
                        '7.5.7 виконання Покупцями своїх зобов\'язань по оплаті замовлених у Продавців товарів.',
                        '7.6. Адміністрація Маркетплейсу не несе відповідальності за дії систем переказів, платіжних систем і за затримки пов\'язані з їх роботою.',
                        '7.7. Користувач приймає умову, згідно з якою всі товари, що пропонуються через Маркетплейс Продавцями, надаються ними під свою самостійну відповідальність та продаж таких товарів ніяк не пов\'язаний з діяльністю Маркетплейсу. Покупець бере на себе повну відповідальність та ризики за отримання товарів, пропонованих Продавцями за допомогою розміщення відповідної інформації на Маркетплейсі.',
                        '7.8. У разі пред\'явлення третіми особами, в тому числі іншим Користувачем, претензій до Власника Маркетплейсу, пов\'язаних з використанням Користувачем Маркетплейсу, такий Користувач зобов\'язується своїми силами і за свій рахунок врегулювати такі претензії з третіми особами, захистивши Адміністрацію Маркетплейсу від можливих збитків і розглядів, або виступити на боці Адміністрації Маркетплейсу в таких розглядах, а також відшкодувати збитки Власнику Маркетплейсу (включаючи судові витрати), завдані йому у зв\'язку з претензіями та позовами, що пов\'язані з розміщенням матеріалів і/або діяльністю Користувача на Маркетплейсі.',
                        '7.9. Всі спори Продавець та Покупець щодо товарів, замовлених на Маркетплейсі, зобов\'язуються розглядати та вирішувати між собою. Відповідальність за дотримання вимог ЗУ «Про захист прав споживачів» покладається на сторони електронного правочину – Продавця та Покупця.',
                        '7.10. Адміністрація Маркетплейсу залишає за собою право не розглядати будь-яких звернень, не надавати будь-якої підтримки, консультацій Користувачам Маркетплейсу з питань, що стосуються купівлі-продажу товарів поза межами Маркетплейсу (тобто не через Кошик на Маркетплейсі).',
                    ]
                },
                {
                    title: '8. Контактна інформація',
                    items: [
                        '8.1. Якщо у Користувача є будь-які запитання або скарги щодо дотримання Адміністрацією Маркетплейсу умов цієї Угоди, або Користувач хоче надати будь-які рекомендації чи коментарі, він може направити листа електронною поштою на адресу: cupcult.official@gmail.com.',
                    ]
                }
            ]
        }
    }
}
</script>
